import { z } from 'zod'
import { DimensionValueTypes } from './types'

export const MAX_VALUE_FOR_DIMENSIONS = 10000
export const MIN_VALUE_FOR_DIMENSIONS = 0.01

const INVALID_TYPE_ERROR_MESSAGE = 'Every side must be more than 0'
const UNIVERSAL_ERROR_MESSAGE = `Side length must be in range ${MIN_VALUE_FOR_DIMENSIONS}-${MAX_VALUE_FOR_DIMENSIONS} inches`

export const dimensionsSchemaValidator = z.object({
  [DimensionValueTypes.L]: z
    .number({
      invalid_type_error: INVALID_TYPE_ERROR_MESSAGE,
      required_error: INVALID_TYPE_ERROR_MESSAGE,
    })
    .min(MIN_VALUE_FOR_DIMENSIONS, UNIVERSAL_ERROR_MESSAGE)
    .max(MAX_VALUE_FOR_DIMENSIONS, UNIVERSAL_ERROR_MESSAGE),
  [DimensionValueTypes.W]: z
    .number({
      invalid_type_error: INVALID_TYPE_ERROR_MESSAGE,
      required_error: INVALID_TYPE_ERROR_MESSAGE,
    })
    .min(MIN_VALUE_FOR_DIMENSIONS, UNIVERSAL_ERROR_MESSAGE)
    .max(MAX_VALUE_FOR_DIMENSIONS, UNIVERSAL_ERROR_MESSAGE),
  [DimensionValueTypes.H]: z
    .number({
      invalid_type_error: INVALID_TYPE_ERROR_MESSAGE,
      required_error: INVALID_TYPE_ERROR_MESSAGE,
    })
    .min(MIN_VALUE_FOR_DIMENSIONS, UNIVERSAL_ERROR_MESSAGE)
    .max(MAX_VALUE_FOR_DIMENSIONS, UNIVERSAL_ERROR_MESSAGE),
})
